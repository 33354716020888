<template>
  <div id="compbox">
    <div class="hadheight"></div>
    <Header />
    <!-- 开发票 -->
    <div class="salesbox">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="fz_title">
                        <div class="ft1">
                            <h1 class="f_tit basefont30">申请开票</h1>
                        </div>
                        <router-link to="/my/myorder" @click="$router.back();" class="but">
                            <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                            <span>返回订单列表</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="cont">
                <div class="lt">
                    <div class="invoice_nei">
                         <template v-for="(item,index) in list">
                            <div class="item" :key="index">
                                <div class="list">
                                    <div class="f_jt">
                                        <div class="font">
                                            <div class="tit font24">
                                                <strong>发票抬头：</strong>
                                            </div>
                                            <div class="text">
                                                {{item.invoiceHeader}}
                                            </div>
                                        </div>
                                        <div class="qei">
                                            <div class="flabel">
                                                <span>{{invoiceType[Number(item.invoiceType)]}}</span>
                                            </div>
                                            <a href="javascript:;" @click="xuanzhong(index)" :class="{check:true,on:listIndex==index}">
                                                <i></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="but">
                                        <div class="f_but" @click.stop="removeInvoice(item)">删除</div>
                                        <div class="f_but" @click.stop="editInvoice(item)">编辑</div>
                                    </div>
                                </div>
                            </div>
                         </template>
                         <div class="item active">
                            <a href="javascript:;" @click="addInvoice()" class="list">
                                <div class="f_jt">
                                    <div class="font">
                                        <div class="tit font24">
                                            <strong>站位</strong>
                                        </div>
                                        <div class="text">
                                            站位
                                        </div>
                                    </div>
                                </div>
                                <div class="but">
                                    <div class="f_but">编辑</div>
                                </div>
                                <div class="f_text">+ 新建发票抬头</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="gt">
                   <div class="invoice_gt">
                        <div class="f_tit basefont24">收票邮箱</div>
                        <input type="text" placeholder="请输入您的邮箱" v-model="emailval" class="f_email">
                        <div class="fz_but">
                            <div class="hm-but but" @click="subInvoice()">
                                <span>提交申请</span>
                            </div>
                            <!-- <div class="hm-but but">
                                <span>不开发票</span>
                            </div> -->
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 开发票弹窗
        title 标题名称
        invoclass 类型判断传递true为申请开发票  false
        当类型判断为false的时候 invoData要有值
     -->
    <transition name="fade">
        <invoicePopup 
            :title="altTitle"
            :invoclass = "invoclass"
            @afteFadeOut="afteFadeOut"
            @upInvoice="getInvoice"
            :invoData = "invoData"
            v-show="invoBo">
        </invoicePopup>
    </transition>
    <!-- 弹窗 -->
    <message-popup
        @altOut="altOut"
        @besure="besure"
        :title="msgTit"
        :isShow="isShow">
    </message-popup>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import invoicePopup from '@/components/pooup/invoice-popup.vue';
import messagePopup from '@/components/pooup/message-popup.vue';
export default {
  components: {
    Header,
    Footer,
    invoicePopup,
    messagePopup
  },
  data(){
    return {
       listIndex: -1,
       list:[], //历史发票的列表
       invoclass: false,
       invoBo: false,
       invoData: {},
       altTitle: "",
       emailval: '',
       invoiceType: ['个人发票','企业普票','企业专票'],
       id: '',
       msgTit: '',
       isShow: false,
       removeObj: {}
    }
  },
  mounted(){
      
  },
  beforeRouteEnter(to,from,next){
    next( v => {
        v.getInvoice(); //获取发票
    });
  },
  beforeRouteUpdate(to,from,next){
    /* 路由更新获取发票 */
    this.getInvoice();
    next();
  },
  methods: {
    getInvoice(){ //获取我的开票信息（历史开票信息）
        this.$axios.post('/api2/portal/auth/user/invoiceHeader/list').then( res => {
            if(res.code === "000000"){
                console.log(res);
                this.list = res.data;
            }
        }).catch( error => {
            console.log(error);
        });
    },
    addInvoice(){ //新建发票抬头
        this.altTitle = "申请开票";
        this.invoData = {};
        this.invoclass = true;
        this.invoBo = true;
    },
    editInvoice(obj){ //编辑发票
        this.altTitle = "编辑发票";
        this.invoData = obj;
        this.invoclass = false;
        this.invoBo = true;
    },
    async besure(){  //弹窗确认
        let obj = this.removeObj;
        this.altOut();
        let res = await this.$axios.post("/api2/portal/auth/user/invoiceHeader/delete",{
            id: obj.id
        });
        if(res.code == "000000"){
           this.getInvoice();
        }else{
            this.$message.Message({
                message: "删除失败，请稍后重试！",
                type: 'error'
            });
        }
    },
    removeInvoice(obj){
         this.msgTit = `
            <p>是否删除 “${obj.invoiceHeader}”</p>
            <p>请检查无误后再删除该发票</p>
       `
        this.isShow=true;
        this.removeObj = obj;
    },
    altOut(){
        this.isShow = false;
        this.removeObj = {};
    },
    subInvoice(){ //提交收票箱
        var cod = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
        if(this.listIndex == -1){
            return this.$message.error("请勾选要开的发票！");
        }
        if(!cod.test(this.emailval)){
            return this.$message.error("请填写正确的邮箱");
        }
        let invoiceHeaderId = this.list[this.listIndex].id;
        let orderNo = this.$route.params.id;
        let receiveEmail = this.emailval;
        console.log(invoiceHeaderId,orderNo,receiveEmail);
        this.$axios.post('/api2/portal/auth/order/invoiceRecord/insert',{
            invoiceHeaderId,
            orderNo,
            receiveEmail
        }).then( res => {
            console.log(res);
            if(res.code=="000000"){
                this.$message({
                    message: "提交成功！",
                    type: 'success'
                });
                this.$router.push({
                    path: "/my/myorder"
                });
            }else{
                this.$message({
                message: res.message,
                type: "error"
            });
            }
        }).catch( error => {
            console.log(error);
            
            
        });

    },
    xuanzhong(_in) { //发票选中
       if(this.listIndex == _in){
            this.listIndex = -1;
        }else{
            this.listIndex = _in;
        }
    },
    afteFadeOut(_val){ //弹窗隐藏
        this.invoBo = _val;
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>