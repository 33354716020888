<template>
  <div class="invoaltbox">
    <div class="bag" @click="afteFadeOut()"></div>
    <div class="f_cont">
      <a href="javascript:;" @click="afteFadeOut()" class="close"
        ><img src="../../assets/images/closeiconf1.png" alt=""
        ><img src="../../assets/images/closeiconf2.png" alt=""
      /></a>
      <div class="fz_nei">
        <div class="tit basefont30">{{ title }}</div>
        <form action="" class="form">
            <div class="item fz_class">
            <div class="name">售后类型：</div>
            <div class="fz_jut">
                <label
                ><input
                    type="radio"
                    name="lei"
                    v-model="invoData.invoiceType"
                    value="1"
                /><i></i><span>企业普票</span></label
                >
                <label
                ><input
                    type="radio"
                    name="lei"
                    v-model="invoData.invoiceType"
                    value="2"
                /><i></i><span>企业专票</span></label
                >
                <label
                ><input
                    type="radio"
                    name="lei"
                    v-model="invoData.invoiceType"
                    value="0"
                /><i></i><span>个人专票</span></label
                >
            </div>
            </div>
            <div v-show="invoData.invoiceType != undefined">
                <div class="item">
                    <div class="name">*发票抬头 ：</div>
                    <div class="fz_jut">
                        <input
                        type="text"
                        placeholder="请输入发票抬头"
                        v-model="invoData.invoiceHeader"
                        />
                    </div>
                </div>
                <div class="item" v-show="invoData.invoiceType != 0">
                    <div class="name">*纳税人识别号 ：</div>
                    <div class="fz_jut">
                        <input
                        type="text"
                        placeholder="请输入纳税人识别号"
                        v-model="invoData.invoiceTaxNo"
                        />
                    </div>
                </div>
                <div v-show="invoData.invoiceType == 2">
                    <div class="item">
                        <div class="name">*注册地址 ：</div>
                        <div class="fz_jut">
                            <input
                            type="text"
                            placeholder="请输入注册地址"
                            v-model="invoData.invoiceRegisterAddress"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="name">*注册电话 ：</div>
                        <div class="fz_jut">
                            <input
                            type="text"
                            placeholder="请输入注册电话"
                            v-model="invoData.invoiceRegisterPhone"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="name">*开户银行 ：</div>
                        <div class="fz_jut">
                            <input
                            type="text"
                            placeholder="请输入开户银行"
                            v-model="invoData.invoiceBank"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="name">*银行账户 ：</div>
                        <div class="fz_jut">
                            <input
                            type="text"
                            placeholder="请输入银行账户"
                            v-model="invoData.invoiceBankNo"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button type="submi" @click.prevent="invoSubmit()" class="hm-but">
            <span>提交申请</span>
            <i>
                <img src="../../assets/images/jiantouyo1.png" />
                <img src="../../assets/images/jiantouyo2.png" />
            </i>
            </button>
        </form>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
    props:{
        title: String,
        invoclass: Boolean,
        invoData: Object
    },
    data(){
        return {
            // form: {
            //     id: "1663484488453197826",
            //     invoiceBank: null,
            //     invoiceBankNo: null,
            //     invoiceHeader: null,
            //     invoiceRegisterAddress: null,
            //     invoiceRegisterPhone: null,
            //     invoiceTaxNo: "91440300326574477D",
            //     invoiceType:"1",
            //     userId:"1632914605139349506"
            // }
        }
    },
    methods:{
        async invoSubmit(){ //提交申请
            // 判断类型
            if(this.invoData.invoiceType == '' || this.invoData.invoiceType == undefined) return this.$message.error('请选择售后类型！');
            // 发票抬头
            if(this.invoData.invoiceHeader == '' || this.invoData.invoiceHeader == undefined) return this.$message.error('请填写发票抬头！');
            // 纳税人识别号
            if(this.invoData.invoiceType!=0){
                if(!/^[A-Za-z\d]{15,20}$/.test(this.invoData.invoiceTaxNo) || this.invoData.invoiceTaxNo == undefined){
                    return this.$message.error('请填写正确的纳税人识别号！');
                }
            }
            /* 企业专票的判断 */
            if(this.invoData.invoiceType == 2){
                // 注册地址
                if(this.invoData.invoiceRegisterAddress == "" || this.invoData.invoiceRegisterAddress == undefined){
                    return this.$message.error('注册地址不能为空！');
                }
                let phon = Number(this.invoData.invoiceRegisterPhone);
                // 注册电话
                if(!this.validatePhoneNumber(phon) || phon == undefined){
                    return this.$message.error('请填写正确的注册电话！');
                }
                // 开户银行
                if(this.invoData.invoiceBank == "" || this.invoData.invoiceBank == undefined){
                    return this.$message.error('开户银行不能为空！');
                }
                // 银行账户
                if(!/^([1-9]{1})(\d{14}|\d{18})$/.test(this.invoData.invoiceBankNo) || this.invoData.invoiceBankNo == undefined){
                    return this.$message.error('请填写正确的银行账户！');
                }
            }
            let res;
            if(this.invoclass){  //申请开发票
                res = await this.$axios.post('/api2/portal/auth/user/invoiceHeader/insert',this.invoData);
            }else{ //编辑开发票
                res = await this.$axios.post('/api2/portal/auth/user/invoiceHeader/update',this.invoData);
            }
            if(res.code == "000000"){
                this.afteFadeOut();
                this.$emit("upInvoice");
            }
        },
        afteFadeOut(){
            this.$emit("afteFadeOut",false);
        },
        validatePhoneNumber(number) {
            var mobilePattern = /^1[3456789]\d{9}$/;
            var telPattern = /^(0\d{2,3}-)?\d{7,8}$/;
            
            return mobilePattern.test(number) || telPattern.test(number);
        }
    }
}
</script>
